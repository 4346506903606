/*@import "@angular/material/theming";
@include mat-core();
$angular-primary: mat-palette($mat-teal, 500, 100, 900);
$angular-accent: mat-palette($mat-pink, A200, A100, A400);
$angular-warn: mat-palette($mat-red);
$angular-default-theme: mat-light-theme(
  (
    color: (
      primary: $angular-primary,
      accent: $angular-accent,
      warn: $angular-warn,
    ),
  )
);
@include angular-material-theme($angular-default-theme);
$angular-dark-theme: mat-dark-theme(
  (
    color: (
      primary: $angular-primary,
      accent: $angular-accent,
      warn: $angular-warn,
    ),
  )
);
.darkMode {
  @include angular-material-color($angular-dark-theme);
}
.darkMode .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: #ffee00;
}
.darkMode .mat-app-background, .darkMode.mat-app-background {
    background-color: #303030;
    h1, h2 {
      color:white;
    }
    button {
      color: #177ddc;
    }
    .grid {
      > div {
        background: white;
        a {
          color: #303030;
        }
      }
    }
}*/
// html,
// body {
//   height: 100%;
// }
body {
  margin: 0;
  font-family: "Source Sans Pro", "Roboto", "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: 300;
  background-color: #ffffff;
  color: #000000;
}
// a {
//   text-decoration: underline;
// }
h1,
h2,
h3 {
  color: #000000;
}
h1 {
  // font-size: 26px;
  // font-weight: 400;
  line-height: 40px;
}
// h2 {
// font-size: 18px;
// font-weight: 400;
// line-height: 20px;
// padding-bottom: 8px;
// }
h3 {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
ul,
ol {
  padding-left: 12px;
}
li {
  font-size: 18px;
  font-weight: 400;
}
button {
  color: #ffffff;
  width: 130px;
  height: 36px;
  background: #0b828b;
  border-radius: 5px;
  border: none;
  font-size: 14px;
  margin-bottom: 10px;

  a,
  a:hover,
  a:visited {
    color: #ffffff;
    // text-decoration: none;
  }
}
// input[type="text"], input[type="email"], input[type="password"] {
//   width: 375px;
//   display: block;
//   background: #ffffff;
//   border: 1px solid #a0a0a0;
//   box-sizing: border-box;
//   border-radius: 2px;
// }
input[type="radio"] {
  width: 8px;
  height: 8px;
}
.brickendon-group {
  display: block;
  padding-bottom: 15px;
  font-size: 1.5rem;
  line-height: 1.25;

  label {
    padding-left: 10px;
    font-weight: 400;
    font-size: 1rem;
  }
}
.invalid {
  border-left: red;
  border-left-style: solid;
  padding-left: 4px;
}

form {
  label {
    margin-bottom: 5px;
    font-size: 19px;
    line-height: 25px;
    color: #0b0c0c;
  }
}
.secondary-button {
  cursor: pointer;
  background: #dee0e2;
  box-shadow: inset 0px -3px 0px rgba(0, 0, 0, 0.448864);
  border-radius: 3px;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #0b0c0c;
}
.danger-button {
  cursor: pointer;
  background: #b10e1e;
  box-shadow: inset 0px -3px 0px rgba(0, 0, 0, 0.448864);
  border-radius: 3px;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #ffffff;
}
.govuk-back-link:link {
  margin-bottom: 35px;
}

//
.mat-country-select-site {
  background-color: white !important;
  border-style: groove;
  margin-left: 11px !important;  
  
  .mat-select-search-input {
    background-color: white !important;
    margin-top: 0px;    
  }
}

.mat-country-search-registration {
  background-color: white !important;
  border-style: groove;
}

.iti__dial-code {
  color: #000 !important;
}
.text-align{
  word-wrap: break-word !important;
} 
.govuk-breadcrumbs__link {
  font-size: 18px !important;
}
.govuk-breadcrumbs__link:hover, .govuk-breadcrumbs__link:focus, .govuk-breadcrumbs__link:active {
  text-decoration: underline;
  text-decoration-thickness: max(3px, 0.1875rem, 0.12em);
  text-decoration-skip-ink: none;
}
.govuk-breadcrumbs__link:not(a):hover, .govuk-breadcrumbs__link:not(a):focus, .govuk-breadcrumbs__link:not(a):active {
  text-decoration: none;
}

.gov-uk-actual-ul{
  padding-left: revert;
}